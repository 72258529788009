import Vue from 'vue'
import VueRouter from 'vue-router'
import Customers from '../views/customers/Customers.vue'
import Ptssettings from '../views/ptssettings/Ptssettings.vue'
import Syslogs from '../views/syslogs/Syslogs.vue'


import store from '../vuex'

Vue.use(VueRouter)

const ifNotAuthenticated = (to, from, next) => {
  //console.log("isLogin",store.getters.gLogin);
  if (!store.getters.gLogin) {
    next()
    return
  }  
  next('/')

  // if (!store.getters.loginState) {
  //   //console.log('!loginData:',store.getters.loginData);
  //   next()
  //   return
  // }  
  // next('/')
}

const ifAuthenticated = (to, from, next) => {
  if (store.getters.gLogin) {
    next()
    return
  }
  next('/')

  // if (store.getters.loginState) {
  //   console.log('loginState:',store.getters.loginState);
  //   next()
  //   return
  // }
  // next('/')
}



const routes = [
  { path: '/',name: 'home',component: () => import("../views/Login.vue"), beforeEnter:ifNotAuthenticated, },
  { path: '/login',name: 'login',component: () => import("../views/Login.vue"), beforeEnter:ifNotAuthenticated, },
  { path: '/logout',name: 'logout',component: () => import("../views/Logout.vue"),},  
  { path: '/dashboard', name: 'dashboard', component: () => import("../views/dashboard/Dashboard.vue"), beforeEnter:ifAuthenticated, },
  { path: '/customers', name: 'Customer', component: Customers, beforeEnter:ifAuthenticated,  },
  { path: '/customers/detail/:customer_id', name: 'customers-details', component: () => import("../views/customers/CustomersDetails.vue") },
  { path: '/customers/recentlist', name: 'customers-recentlist', component: () => import("../views/customers/CustomersRecentList.vue"), beforeEnter:ifAuthenticated },
  { path: '/customers/search', name: 'customers-search', component: () => import("../views/customers/CustomersSearch.vue"), beforeEnter:ifAuthenticated },
  { path: '/customers/registration', name: 'customers-registration', component: () => import("../views/customers/CustomersRegistration.vue"), beforeEnter:ifAuthenticated },
  
  //merchant Router
  { path: '/merchants', name: 'merchants', component: () => import("../views/merchants/Merchants.vue") },
  { path: '/merchants/add', name: 'merchant-add', component: () => import("../views/merchants/MerchantAdd.vue") },
  { path: '/merchants/detail/:merchant_id', name: 'merchant-details', component: () => import("../views/merchants/MerchantDetails.vue") },
  { path: '/merchants/outlets/', name: 'outlet-list-all', component: () => import("../views/merchants/MerchantOutlets.vue") },  
  { path: '/merchants/outlets/:merchant_id/:enc_merchant_name', name: 'outlet-list', component: () => import("../views/merchants/MerchantOutlets.vue") },
  { path: '/merchants/outlets/detail/:merchant_id/:enc_merchant_name/:outlet_id/:enc_outlet_name', name: 'outlet-details', component: () => import("../views/merchants/MerchantOutletDetails.vue") },  
  { path: '/merchants/edc/', name: 'edc-list-all', component: () => import("../views/merchants/MerchantEdc.vue") },  
  { path: '/merchants/edc/:merchant_id/:enc_merchant_name', name: 'edc-list', component: () => import("../views/merchants/MerchantEdc.vue") },
  { path: '/merchants/edc/detail/:merchant_id/:enc_merchant_name/:edc_id/:edc_login_id', name: 'edc-details', component: () => import("../views/merchants/MerchantEdcDetails.vue") },  
  { path: '/merchants/users/', name: 'user-list-all', component: () => import("../views/merchants/MerchantUsers.vue") },  
  { path: '/merchants/users/:merchant_id/:enc_merchant_name', name: 'user-list', component: () => import("../views/merchants/MerchantUsers.vue") },
  { path: '/merchants/users/detail/:merchant_id/:enc_merchant_name/:user_id/:enc_full_name', name: 'muser-details', component: () => import("../views/merchants/MerchantUserDetails.vue") },  
  { path: '/merchants/edcrt/', name: 'edcrt-list', component: () => import("../views/merchants/EdcReceiptTemplate.vue") },  
  { path: '/merchants/edcrt/detail/:edc_rcpt_template_id/', name: 'edcrt-details', component: () => import("../views/merchants/EdcReceiptTemplateDetails.vue") },  
  { path: '/merchants/groups', name: 'mg-list-all', component: () => import("../views/merchants/MerchantUserGroups.vue") },
  { path: '/merchants/groups/detail/:merchant_user_group_id', name: 'mg-details', component: () => import("../views/merchants/MerchantUserGroupDetails.vue") },    





  { path: '/callcenter/add', name: 'callcenter-add', component: () => import("../views/callcenter/CallcenterAdd.vue") },  
  { path: '/callcenter/recentlist', name: 'callcenter-recentlist', component: () => import("../views/callcenter/CallcenterRecentList.vue") },
  { path: '/callcenter/search', name: 'callcenter-search', component: () => import("../views/callcenter/CallcenterSearch.vue") },
  { path: '/callcenter/detail/:cce_ref_no/:cc_enquiry_id', name: 'callcenter-details', component: () => import("../views/callcenter/CallcenterDetails.vue") },  
  

  { path: '/catalogues', name: 'catalogues', component: () => import("../views/catalogues/Catalogues.vue") },
  { path: '/catalogues/detail/:catalogue_id', name: 'catalogue-details', component: () => import("../views/catalogues/CatalogueDetails.vue") },
  { path: '/catalogues/items', name: 'ci-list-all', component: () => import("../views/catalogues/CataloguesItems.vue") },
  { path: '/catalogues/items/detail/:catalogue_item_id', name: 'ci-details', component: () => import("../views/catalogues/CatalogueItemDetails.vue") },    
  { path: '/suppliers', name: 'suppliers', component: () => import("../views/catalogues/Suppliers.vue") },
  { path: '/suppliers/detail/:supplier_id', name: 'supplier-details', component: () => import("../views/catalogues/SupplierDetails.vue") },

  { path: '/marketing/banners', name: 'marketing_banners', component: () => import("../views/marketing/Banners.vue") },
  { path: '/marketing/banners/detail/:marketing_banner_id', name: 'marketing-banner-details', component: () => import("../views/marketing/BannerDetails.vue") },

  { path: '/marketing/pages', name: 'marketing-pages', component: () => import("../views/marketing/Pages.vue") },
  { path: '/marketing/pages/detail/:page_id/:enc_page_name', name: 'marketing-page-details', component: () => import("../views/marketing/PageDetails.vue") },

  { path: '/notification/settings', name: 'notification-settings', component: () => import("../views/notification/NotificationSettings.vue") },
  { path: '/notification/settings/detail:notification_setting_id/:enc_ns_name', name: 'notification-setting-details', component: () => import("../views/notification/NotificationSettingDetails.vue") },

  { path: '/notification/templates', name: 'notification-templates', component: () => import("../views/notification/NotificationTemplates.vue") },
  { path: '/notification/templates/detail:notification_template_id/:enc_nt_name', name: 'notification-template-details', component: () => import("../views/notification/NotificationTemplateDetails.vue") },

  { path: '/notification/categories', name: 'notification-categories', component: () => import("../views/notification/NotificationCategories.vue") },
  { path: '/notification/categories/detail:notification_category_id/:enc_nc_name', name: 'notification-category-details', component: () => import("../views/notification/NotificationCategoryDetails.vue") },

  { path: '/notification/broadcast', name: 'notification-broadcast', component: () => import("../views/notification/NotificationBroadcast.vue") },
  { path: '/notification/broadcast/detail:notification_broadcast_id/:enc_nb_name', name: 'notification-broadcast-details', component: () => import("../views/notification/NotificationBroadcastDetails.vue") },
  { path: '/notification/broadcast/sendlist:notification_broadcast_id/:enc_nb_name', name: 'notification-broadcast-sendlist', component: () => import("../views/notification/NotificationBroadcastSendList.vue") },


  { path: '/notification/test', name: 'notification-test', component: () => import("../views/notification/vue-register-fcm.vue") },


  { path: '/transactions/recentlist', name: 'transactions-recentlist', component: () => import("../views/transactions/TransactionsRecentList.vue") },
  { path: '/transactions/detail/:process_reference_no', name: 'point_transaction_details', component: () => import("../views/transactions/TransactionDetails.vue") },  
  { path: '/transactions/search', name: 'point_transactions_search', component: () => import("../views/transactions/TransactionsSearch.vue") },
  { path: '/transactions/issuance', name: 'point_transactions_issuance', component: () => import("../views/transactions/TransactionsIssuance.vue") },  

  { path: '/redemptions/recentlist', name: 'redemptions-recentlist', component: () => import("../views/redemptions/RedemptionsRecentList.vue") },
  { path: '/redemptions/search', name: 'redemptions-search', component: () => import("../views/redemptions/RedemptionsSearch.vue") },
  { path: '/redemptions/detail/:process_reference_no/:redemption_id', name: 'redemption_details', component: () => import("../views/redemptions/RedemptionDetails.vue") },  

  { path: '/ptssettings', name: 'Ptssettings', component: Ptssettings },
  { path: '/ptssettings/campaigns', name: 'pts_campaigns', component: () => import("../views/ptssettings/PtsCampaign.vue") },
  { path: '/ptssettings/campaigns/detail/:earned_campaign_id', name: 'pts_campaign_details', component: () => import("../views/ptssettings/PtsCampaignDetails.vue") },  
  { path: '/ptssettings/models', name: 'pts_models', component: () => import("../views/ptssettings/PtsModel.vue") },
  { path: '/ptssettings/models/detail/:earned_model_id', name: 'pts_model_details', component: () => import("../views/ptssettings/PtsModelDetails.vue") },  
  { path: '/ptssettings/groups', name: 'pts_groups', component: () => import("../views/ptssettings/PtsGroup.vue") },
  { path: '/ptssettings/group/detail/:earned_group_id', name: 'pts_group_details', component: () => import("../views/ptssettings/PtsGroupDetails.vue") },  

  { path: '/fileupload/list/:route_type', name: 'fileupload-list', component: () => import("../views/fileupload/FileUploadList.vue") },
  { path: '/fileupload/details/:route_type/:file_upload_id', name: 'fileupload-details', component: () => import("../views/fileupload/FileUploadListDetails.vue") },
  { path: '/fileupload/wsdetails/:route_type/:file_upload_id/:ws_index', name: 'fileupload-wsdetails', component: () => import("../views/fileupload/FileUploadWorksheetDetails.vue") },
  { path: '/fileupload/wspreview/:route_type/:file_upload_id/:file_upload_template_id/:ws_index', name: 'fileupload-wspreview', component: () => import("../views/fileupload/FileUploadWorksheetPreview.vue") },
  
  { path: '/fileupload/templates/', name: 'fileupload-template_list', component: () => import("../views/fileupload/FileUploadTemplates.vue") },
  { path: '/fileupload/templates/detail/:file_upload_template_id', name: 'fileupload-template_details', component: () => import("../views/fileupload/FileUploadTemplateDetails.vue") },  


  { path: '/users', name: 'users', component: () => import("../views/users/Users.vue") },
  { path: '/users/detail/:admin_user_id', name: 'user-details', component: () => import("../views/users/UserDetails.vue") },
  { path: '/users/groups', name: 'ug-list-all', component: () => import("../views/users/UserGroups.vue") },
  { path: '/users/groups/detail/:admin_group_id', name: 'ug-details', component: () => import("../views/users/UserGroupDetails.vue") },    

  { path: '/settings', name: 'settings', component: () => import("../views/settings/Settings.vue") },
  { path: '/settings/detail/:sys_setting_id', name: 'setting-details', component: () => import("../views/settings/SettingDetails.vue") },
  
  { path: '/txemail', name: 'txemail', component: () => import("../views/settings/SettingTxEmail.vue") },
  { path: '/txemail/detail/:email_setting_id', name: 'txemail-details', component: () => import("../views/settings/SettingTxEmailDetails.vue") },
  
  { path: '/syslogs', name: 'Syslogs', component: Syslogs },
  { path: '/admin/profile', name: 'admin-profile', component: () => import("../views/admin/AdminUserDetails.vue") },
  { path: '/admin/changepassword', name: 'admin-changepassword', component: () => import("../views/admin/AdminUserChangePassword.vue"), beforeEnter:ifAuthenticated, },

  { path: '/logs/:log_for', name: 'logs-list', component: () => import("../views/logs/LogsList.vue") },

  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  { path: '*', redirect: '/dashboard' }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  //base: '/adminportal',
  routes
})

export default router
