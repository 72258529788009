import 'material-design-icons-iconfont/dist/material-design-icons.css' // Ensure you are using css-loader
import '@mdi/font/css/materialdesignicons.css' 
import '@fortawesome/fontawesome-free/css/all.css'
import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import colors from 'vuetify/lib/util/colors'
// import VeeValidate from 'vee-validate';
Vue.use(require('vue-moment'));
Vue.use(Vuetify);
// Vue.use(VeeValidate);

export default new Vuetify({
    icons: {
        iconfont: 'md' || 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
      },

    theme: {
      themes: {
          light: {
              panel1: colors.blue.darken3,
              panel2: colors.blue.darken4,
              panel3: colors.indigo.darken3,
              panel4: colors.indigo.darken4,
              panelImage: colors.indigo.darken2,
              panelSysInfo: colors.indigo.darken1,
              cbtnSave: colors.green.darken2,
              cbtnRed: colors.red.darken2,
              btnUpload: colors.deepOrange.darken1,
              btnLibrary: colors.yellow.darken4,
          },
          dark: {
            panel1: colors.blue.lighten3,
            panel2: colors.blue.lighten4,
            panel3: colors.indigo.lighten3,
            panel4: colors.indigo.lighten4,
            panelImage: colors.indigo.lighten2,
            panelSysInfo: colors.indigo.lighten1,
            cbtnSave: colors.green.lighten2,
            cbtnRed: colors.red.lighten2,
            btnUpload: colors.deepOrange.lighten1,
            btnLibrary: colors.yellow.lighten4,
        },

      }
    }    

});
 