import axios from "axios"
let vlogin_id = localStorage.getItem('login_id');
class ApParameterService {


    get_parameter(form) {
        vlogin_id = localStorage.getItem('login_id');
        form['login_id'] = vlogin_id;
        return axios.post("ap_parameter/get_parameter", form);

    }

    get_parameter_v2(form) {
        return axios.post("ap_parameter/get_parameter_v2", form);
    }
    
  
}

export default new ApParameterService();