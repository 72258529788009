import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify';
import './axios'
import store from './vuex'

Vue.config.productionTip = false
//import firebaseMessaging from 'firebase/messaging'
//Vue.prototype.$messaging = firebaseMessaging
new Vue({
  router,
  vuetify,
  store,
  render: h => h(App)
}).$mount('#app')
